<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
  >
    <b-tabs class="shadow p-2">
      <div class="d-flex  align-items-center justify-content-between">
        <div class="d-flex align-items-baseline ">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$router.back()"
          >
            <feather-icon
              class="mr-25"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <h3 class="ml-1">
            All Resources
            <b-badge
              variant="primary"
              class="ml-50"
            >
              {{ $route.params.mname }}
            </b-badge>
          </h3>
        </div>
        <div class="d-flex align-items-baseline ">
          <b-form-group
            class="mb-0"
            label="View as"
            label-for="btn-radios-1"
            label-size="sm"
          >
            <b-form-radio-group
              id="btn-radios-1"
              v-model="view"
              :options="[
                { text: 'Grid', value: 'grid' },
                { text: 'Table', value: 'table' },
              ]"
              buttons
              size="sm"
              button-variant="light"
            />
          </b-form-group>
        </div>
      </div>
      <hr>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search resource"
            type="text"
          />
          <b-button
            v-b-modal.knowledgeBaseModal
            variant="primary"
            class="mr-1"
            @click="update = false; title = ''; description = ''; is_private = true; is_internal = true; recommendationLevel = ''"
          >
            <feather-icon icon="PlusIcon" />
            Add Resource
          </b-button>
        </b-form-group>
      </div>
      <!-- card -->
      <section
        v-if="view==='grid'"
        class="grid-view mt-1"
      >
        <b-row v-if="rows.length===0">
          <b-col>
            <b-card
              tag="article"
              style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              class="ecommerce-card"
            >
              <h6 class="d-flex align-items-center justify-content-center  mb-0 text-center">
                No resources are available
              </h6>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col
            v-for="(row, i) in rows"
            :key="i"
            :title="row.title"
            md="4"
            style="margin-bottom: 10px"
          >
            <b-card
              :key="i"
              header-tag="header"
              tag="article"
              style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              class="ecommerce-card"
            >
              <template #header>
                <b-card-title class="d-flex align-items-center justify-content-center  mb-0 text-center">
                  {{ row.document_name }}
                </b-card-title>
                <div>
                  <b-badge
                    v-if="!row.is_default"
                    :variant="`${row.is_private ? 'primary' : 'secondary'}`"
                  >
                    <feather-icon :icon="`${row.is_private ? 'Lock' : 'Users'}Icon`" />
                    <span style="margin-left:4px">{{ row.is_private ? 'Private' : 'Public' }}</span>
                  </b-badge>
                  <b-badge
                    v-else
                    variant="outline-primary"
                  >
                    Default
                  </b-badge>
                </div>
              </template>
              <b-card-text>{{ row.description }}</b-card-text>
              <b-card-footer class="p-0">
                <div class="d-flex justify-content-between mt-2">
                  <div>
                    <Promised
                      v-if="row.is_internal === true"
                      :promise="getLink(row.file)"
                    >
                      <template v-slot:pending>
                        <b-button
                          class="mr-50"
                          disabled
                          size="sm"
                          variant="outline-primary"
                        ><feather-icon icon="ClockIcon" /></b-button>
                      </template>
                      <template v-slot="data">
                        <b-button
                          :href="data"
                          class="mr-50"
                          size="sm"
                          target="_blank"
                          variant="outline-primary"
                        >View</b-button>
                      </template>
                      <template v-slot:rejected>
                        <span v-b-tooltip="`File not found`">
                          <b-button
                            class="mr-50"
                            disabled
                            size="sm"
                            variant="outline-danger"
                          ><feather-icon icon="AlertTriangleIcon" /></b-button>
                        </span>
                      </template>
                    </Promised>
                    <b-button
                      v-else
                      :href="prependHttps(row.file)"
                      size="sm"
                      target="_blank"
                      variant="outline-primary"
                      class="mr-50"
                    >View</b-button>
                  </div>
                  <div>
                    <b-button
                      v-if="!row.is_default"
                      v-b-modal.knowledgeBaseModal
                      size="sm"
                      class="px-0"
                      variant="flat-primary"
                      @click="update = true; selectedRow = row.id; title = row.document_name; description = row.description; is_private = row.is_private; is_internal = row.is_internal; recommendationLevel = row.recommendation"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      v-if="!row.is_default"
                      size="sm"
                      class="px-0"
                      variant="flat-danger"
                      @click="deleteFile(row.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </section>
      <!-- table -->
      <vue-good-table
        v-if="view === 'table'"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span
            v-if="props.column.field === 'file'"
            class="d-flex flex-nowrap"
          >
            <Promised
              v-if="props.row.is_internal === true"
              :promise="getLink(props.row.file)"
            >
              <template v-slot:pending>
                <b-button
                  class="mr-50"
                  disabled
                  size="sm"
                  variant="outline-primary"
                ><feather-icon icon="ClockIcon" /></b-button>
              </template>
              <template v-slot="data">
                <b-button
                  :href="data"
                  class="mr-50"
                  size="sm"
                  target="_blank"
                  variant="outline-primary"
                >View</b-button>
              </template>
              <template v-slot:rejected>
                <span v-b-tooltip="`File not found`">
                  <b-button
                    class="mr-50"
                    disabled
                    size="sm"
                    variant="outline-danger"
                  ><feather-icon icon="AlertTriangleIcon" /></b-button>
                </span>
              </template>
            </Promised>

            <b-button
              v-else
              :href="prependHttps(props.row.file)"
              size="sm"
              target="_blank"
              variant="outline-primary"
              class="mr-50"
            >View</b-button>
            <b-button
              v-if="!props.row.is_default"
              v-b-modal.knowledgeBaseModal
              size="sm"
              variant="outline-primary"
              @click="update = true; selectedRow = props.row.id; title = props.row.document_name; description = props.row.description; is_private = props.row.is_private; is_internal = props.row.is_internal; recommendationLevel = props.row.recommendation"
            >
              Edit
            </b-button>
            <b-button
              v-if="!props.row.is_default"
              size="sm"
              variant="flat-danger"
              @click="deleteFile(props.row.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>
          <span v-else-if="props.column.field === 'description'">
            {{ props.row.description }}
          </span>
          <span v-else-if="props.column.field === 'recommendation'">
            <b-badge
              variant="light-primary"
              class="mb-25"
            >
              {{ props.formattedRow.recommendation }}
            </b-badge>
          </span>
          <span
            v-else-if="props.column.field === 'visibility'"
            class="d-flex flex-nowrap"
          >
            <b-badge
              v-if="!props.row.is_default"
              :variant="`${props.row.is_private ? 'primary' : 'secondary'}`"
            >
              <feather-icon :icon="`${props.row.is_private ? 'Lock' : 'Users'}Icon`" />
              <span style="margin-left:4px">{{ props.row.is_private ? 'Private' : 'Public' }}</span>
            </b-badge>
            <b-badge
              v-else
              variant="outline-primary"
            >
              Default
            </b-badge>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>

          <span
            v-if="props.column.field === 'document_name'"
            class="d-flex flex-nowrap"
          >
            <b-badge
              v-if="props.row.is_internal"
              variant="light-primary"
              size="sm"
            >External Link</b-badge>
            <b-badge
              v-else
              variant="light-primary"
              size="sm"
            >Document</b-badge>
          </span>
        </template>
        <template
          v-slot:column-filter="
            {
              column,
              updateFilters
            }"
        >
          <span v-if="column.label === 'Operation'">
            <treeselect
              style="border-collapse: separate"
              :normalizer="filterNormalizer"
              :append-to-body="true"
              :options="operations"
              placeholder="Select Phase, Subphase or Activities"
              class="form-control p-0 border-0"
              @input="(val) => updateFilters(column, val)"
            />
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20','30']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-modal
        id="knowledgeBaseModal"
        ok-only
        no-close-on-backdrop
        :ok-title="`${update ? 'Update' : 'Upload'}`"
        size="md"
        :title="`${update ? 'Update' : 'Add'} Resource`"
        @ok="addFile"
      >
        <b-overlay :show="$apollo.loading || mutationLoading">
          <validation-observer
            ref="uploadForm"
            tag="form"
          >
            <b-row>
              <b-col cols="12">

                <b-form-group
                  label="Title"
                  label-size="sm"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <b-form-input
                      v-model="title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Title"
                      required
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Description"
                  label-size="sm"
                >
                  <b-form-textarea
                    v-model="description"
                    placeholder="File Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="7">
                <validation-provider
                  #default="{ errors }"
                  name="Recommendation"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                    label="Recommendation Level"
                    label-size="sm"
                  >
                    <v-select
                      v-model="recommendationLevel"
                      :options="['Priority', 'Interesting', 'Additional', 'Good-Read']"
                      placeholder="Recommendation Level"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="5">
                <!-- primary -->
                <div
                  class="d-flex align-items-center"
                  style="height:100%"
                >
                  <span>Is Private?</span>
                  <b-form-checkbox
                    v-model="is_private"
                    class="ml-50"
                    inline
                    switch
                  ><span class="switch-icon-left">
                     <feather-icon icon="LockIcon" />
                   </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="UsersIcon" />
                    </span></b-form-checkbox>
                </div>
              </b-col>
              <b-col cols="12">
                <!-- primary -->
                <div class="d-flex align-items-center">
                  <span> Resource Type </span>
                  <b-form-group
                    class="mb-0 ml-2"
                  >

                    <b-form-radio-group
                      id="btn-radios-1"
                      v-model="is_internal"
                      :options="[
                        { text: 'External Link', value: true },
                        { text: 'Uplaod File', value: false },
                      ]"
                      buttons
                      size="sm"
                      button-variant="light"
                    />
                  </b-form-group>
                </div>

                <hr>
              </b-col>
              <b-col
                v-if="is_internal"
                cols="12"
              >
                <h6>Upload File</h6>
                <!-- Styled -->
                <validation-provider
                  #default="{ errors }"
                  name="File"
                  rules="size:10000"
                >
                  <b-form-file
                    v-model="file"
                    :state="errors.length > 0 ? false:null"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                v-else
                cols="12"
              >
                <h6>External Link</h6>
                <b-form-input
                  v-model="file_link"
                  placeholder="https://"
                />
              </b-col>
            </b-row>
          </validation-observer>
        </b-overlay>
      </b-modal>
    </b-tabs>
  </b-overlay>
</template>
<script>

import {
  BBadge,
  BCard,
  BButton,
  BCol,
  BFormCheckbox,
  BFormFile,
  BTabs,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCardTitle,
  BFormSelect,
  BCardFooter,
  // BLink,
  BCardText,
  BPagination,
  BRow, BFormRadioGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Promised } from 'vue-promised'
import { getUserData } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, size } from '@core/utils/validations/validations'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BTabs,
    BFormInput,
    BFormGroup,
    BCardTitle,
    BButton,
    VueGoodTable,
    BPagination,
    BCardFooter,
    // BLink,
    BCardText,
    BFormSelect,
    BFormTextarea,
    BFormRadioGroup,
    vSelect,
    Treeselect,
    BBadge,
    BFormCheckbox,
    BFormFile,
    Promised,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      view: 'grid',
      mutationLoading: false,
      update: false,
      searchTerm: '',
      selectedRow: 0,
      pageLength: 10,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'document_name',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Level',
          field: 'recommendation',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '-- No Filter --', // placeholder for search input
            filterDropdownItems: ['Priority', 'Interesting', 'Additional', 'Good-Read'],
          },
        },
        // {
        //   label: 'Visibility',
        //   field: 'visibility',
        // },
        {
          label: 'File',
          field: 'file',
          sortable: false,
        },
      ],
      rows: [],
      is_internal: true,
      is_private: true,
      file_link: null,
      file: null,
      recommendationLevel: null,
      title: null,
      description: '',
      selectedOperation: null,
      required,
      size,
    }
  },
  computed: {
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      if (!arr.length) return []
      const newarr = arr
        .filter(obj => obj.role === 'superadmin')
        .filter(obj => obj) // Remove nulls
      return newarr[0]
    },
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    filterNormalizer(node) {
      return {
        id: node.title,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    getLink(key) {
      return fetch(`https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/?file_key=${key}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => data.url)
    },
    async addFile(bvModalEvent) {
      bvModalEvent.preventDefault()
      const validation = await this.$refs.uploadForm.validate()
      if (!validation) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please fill all required fields',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.file && !this.file_link && !this.update) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please upload a file or provide a link',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
        return false
      }
      this.mutationLoading = true
      let fileLink = this.file_link
      if (this.is_internal && this.file) {
        const formData = new FormData()
        formData.append('file', this.file)
        await fetch('https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/upload/', {
          method: 'POST',
          body: formData,
        }).then(response => response.json())
          .then(data => {
            fileLink = data.file_key
            return data
          })
      }
      await this.$apollo.mutate(
        {
          mutation: gql`mutation MyMutation {
                    ${this.update ? `
                      update_programs_knowledgeresourcetable_by_pk(pk_columns: {id: ${this.selectedRow} }, _set: {document_name: "${this.title}",recommendation: "${this.recommendationLevel}", description: "${this.description}" ${this.file ? `, file: "${fileLink}"` : ''}, is_internal: "${this.is_internal}", incubator_id: "${this.userOrgs.users_organizationtable.id}", is_private: "${this.is_private}", knowledge_resource_id: ${this.$route.params.lid}}) {
                        id
                      }
                    ` : `
                      insert_programs_knowledgeresourcetable_one(object: {document_name: "${this.title}",recommendation: "${this.recommendationLevel}", description: "${this.description}", file: "${fileLink}", is_internal: "${this.is_internal}", is_private: "${this.is_private}", incubator_id: "${this.userOrgs.users_organizationtable.id}", knowledge_resource_id: ${this.$route.params.lid}}) {
                        id
                      }
                    `}
                    }`,
          update: () => {
            this.mutationLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Resource ${this.update ? 'update' : 'added'} successfully`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('knowledgeBaseModal')
            this.$apollo.queries.rows.refetch()
          },
        },
      ).catch(() => {
        this.mutationLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
      })
      this.title = null
      this.file_link = null
      this.description = null
      this.selectedOperation = null
      this.recommendationLevel = null
      this.selectedOperation = null
      return true
    },
    deleteFile(id) {
      // msgOkcofirm
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this file?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete File',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation MyMutation {
                      update_programs_knowledgeresourcetable_by_pk(pk_columns: {id: ${id}}, _set: {is_deleted: true}) {
                        id
                      }
                    }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.rows.refetch()
                },
              },
            )
          }
        })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_knowledgeresourcetable(order_by: { id: desc}, where: {knowledge_resource_id: {_eq:  ${this.$route.params.lid}}, _or: [{is_default: {_eq: true}}, {incubator_id: {_eq: ${this.userOrgs.users_organizationtable.id}}}]}) {
            id
            description
            document_name
            file
            recommendation
            is_internal
            is_default
            is_private
          }
        }`
      },
      update: data => data.programs_knowledgeresourcetable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
